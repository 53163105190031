import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'

import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"


const lightboxOptions = {
    buttons: {
        showDownloadButton: false
    }
}
const MyGallery = ({ data }) => {

    return (
       <div className="container mt-12">
            <SimpleReactLightbox>
                <SRLWrapper options={lightboxOptions}>
                    <div className="grid grid-cols-4 gap-4">
                        {data.photos.edges.map(({node}) => (
                            
                                <a href={node.publicURL} key={node.id}>
                                    <GatsbyImage
                                        image={node.childImageSharp.gatsbyImageData}
                                        alt={node.base.split('-').join(' ').split('.')[0]}
                                    />
                                </a>
                            
                        ))}
                    </div>
                </SRLWrapper>
            </SimpleReactLightbox>
       </div>
    )
}

export const query = graphql`
    query {
        photos: allFile(filter: {relativeDirectory: {eq: "forbidden-fruit/gallery"}}) {
            edges {
                node {
                    id
                    publicURL
                    base
                    childImageSharp {
                        gatsbyImageData(
                            height: 600
                            width: 900
                            placeholder: BLURRED
                            transformOptions: {fit: COVER}
                            webpOptions: {quality: 50}
                        )
                    }
                }
            }
        }
    }
`

export default MyGallery